<template>
     <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-between">
      <div class="col-sm-6">
        <div class="row justify-content-between">
          <div class="col-sm-2">
            <button
              type="button"
              class="btn btn-warning mb-3"
              v-b-modal.modal-Transaction
            >
              <i class="mdi mdi-plus me-1"></i> Add Account
            </button>
          </div>
          <div class="col-sm-3">
            <button
              class="btn btn-secondary waves-effect waves-light uil-export"
              @click="getrpt()"
            >
              Export to CSV
            </button>
          </div>
          <div class="col-sm-2">
            <button
              @click="printpdf('p')"
              v-b-modal.modal-Print
              class="btn btn-secondary waves-effect waves-light uil-file"
            >
              Print PDF
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center fw-normal">
            Show&nbsp;
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @input="handlePageChange(currentPage)"
            ></b-form-select
            >&nbsp;entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-end">
          <label class="d-inline-flex align-items-center fw-normal">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              class="form-control form-control-sm ms-2"
            ></b-form-input>
          </label>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table
        table-class="table table-centered datatable table-card-list"
        thead-tr-class="bg-transparent"
        :items="paymentAccounts"
        :fields="fields"
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template v-slot:cell(check)="data">
          <div class="custom-control custom-checkbox text-center">
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`account${data.item.id}`"
            />
            <label
              class="custom-control-label"
              :for="`account${data.item.id}`"
            ></label>
          </div>
        </template>
        <template v-slot:cell(id)="data">
          <a href="javascript: void(0);" class="text-dark fw-bold">
            {{ data.item.id }}
          </a>
        </template>
        <template v-slot:cell(action)="data">
          <ul class="list-inline mb-0">
            <b-dropdown class="list-inline-item" variant="white" right
            toggle-class="text-muted font-size-18 px-2">
            <template v-slot:button-content>
              <i class="uil uil-ellipsis-v"></i>
            </template>
            <a href="#" class="dropdown-item uil uil-eye">View</a>
            <a href="#" class="dropdown-item uil uil-edit" v-b-modal.modal-Add @click="edit(data)">Edit</a>
            <a href="#" class="dropdown-item uil uil-trash-alt" @click="deleterec(data.index,data.item.id,data.item.sale_id)">Delete</a>
          </b-dropdown>
        </ul>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    <!--modals-->
    <b-modal id="modal-Print" title="Print PDF" hide-footer size="bg" centered>
      <reportdet
        :title="title"
        :orderData="orderData"
        :pl="pl"
        :headers="headers"
        :uniqueCars="uniqueCars"
        :shome="showme"
        v-show="showme"
      ></reportdet>
    </b-modal>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";
  import reportdet from "@/components/report/header";
  import axios from '@/Axiosconfig';
  import Swal from "sweetalert2";

  export default {
    page: {
    title: "Payment Accounts",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, reportdet },
    data() {
      return {
        title: "Accounts",
        items: [
            {
            text: "Accounts",
            },
            {
            text: "Accounts",
            active: true,
            },
        ],
        accountTypes: [],
        paymentAccounts: [],
        headers: null,
        uniqueCars: null,
        showme: true,
        pl: "",
        saleslist: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [1, 5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "sale_id",
        sortDesc: false,
        fileds: [
          { key: 'id', label: 'ID' },
          { key: 'name', label: 'Name' },
          { key: 'account_number', label: 'Account Number' },
          { key: 'account_type', label: 'Account Type' },
          { key: 'opening_balance', label: 'Opening Balance' }
        ]
      };
    },
    computed: {
    rows() {
      return this.paymentAccounts.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.fetchAccountTypes();
    this.fetchPaymentAccounts();
    this.totalRows = this.saleslist.length;
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
      fetchAccountTypes() {
        axios.get(`accounttypes`)
          .then(response => {
            this.accountTypes = response.data['results'];
          })
          .catch(error => {
            console.error('Error fetching account types:', error);
          });
      },
      fetchPaymentAccounts() {
        axios.get(`paymentaccounts/`)
          .then(response => {
            this.paymentAccounts = response.data['results'];
          })
          .catch(error => {
            console.error('Error fetching payment accounts:', error);
          });
      },
      handlePageChange(newPage) {
      this.currentPage = newPage;
      this.limit = this.perPage;
      this.offset = (this.currentPage - 1) * this.perPage;
      this.updatearrays();
    },
    printpdf(pl) {
      //console.log(this.tokenString);
      this.pl = pl;
      const data = this.saleslist.map((row) => ({
        ID: row.id,
        "Name": row.name,
        "A/C No.": row.account_number,
        "Type": row.account_type,
        "Openning Balance": row.opening_balance,
      }));

      //get headers
      const headers = Object.keys(data[0]);
      const cars = [];
      Object.entries(data).forEach((val) => {
        const [key, value] = val;
        console.log(key, value);
        cars.push(Object.values(data[key]));
      });

      const uniqueCars = Array.from(new Set(cars));
      this.headers = headers;
      this.uniqueCars = uniqueCars;
      //alert(headers);
    },

    getrpt() {
      //alert(new Date());
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth();
      const date = d.getDate();

      const hour = d.getHours();
      const min = d.getMinutes();
      const sec = d.getSeconds();
      const msec = d.getMilliseconds();
      const filename =
        year + "-" + month + "-" + date + "-" + hour + "-" + min + "-" + sec + "-" + msec;
      //alert(filename);
      const data = this.saleslist.map((row) => ({
        ID: row.id,
        "Name": row.name,
        "A/C No.": row.account_number,
        "Type": row.account_type,
        "Openning Balance": row.opening_balance,
      }));
      //alert("");
      const csvRows = [];
      //get headers
      const headers = Object.keys(data[0]);

      csvRows.push(headers.join(","));
      //alert(csvRows);
      //loop over the headers
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          // alert(escaped);
          return '"' + escaped + '"'; //'" + escaped + "';
        });
        csvRows.push(values.join(","));
      }
      //alert(csvData);
      const csvData = csvRows.join("\n");
      //alert(csvData);

      const blob = new Blob([csvData], { type: "textcsv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", this.title + filename + ".csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    deleterec(index, id, code) {
      Swal.fire({
        title: "Are you sure, you want to delete sale #" + code + "?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#000000",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("sales/" + id + "/")
            .then(() => {
              this.saleslist.splice(index, 1);
              Swal.fire("Deleted!", code + " has been deleted.", "success");
            })
            .catch((e) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Error!",
                html: e,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
    }
  };
  </script>
  
  <style scoped>
  /* Add custom CSS styles here if needed */
  </style>
  